<template>
  <div class="body">
    <el-container>
      <el-header>
        <div class="logo"><img src="@/assets/txlogo.png"></div>
        <select class="management-select" style="position: absolute; right: 250px; top: 20px;">
          <option value="0">全部</option>
          <option value="1">会员包月</option>
          <option value="2">代币点券</option>
        </select>
        <div class="namediv">{{ this.$store.state.user.store_name }}<img src="@/assets/sj.png" style="margin-left:10px">
        </div>
      </el-header>
      <el-container>
        <el-aside :class="[aside ? 'aside1' : 'aside2']">
          <img src="@/assets/aside.png">
        </el-aside>
        <el-main>
          <i class="icon-menu" @click="aside = !aside"> <img style="height:17px;width:11px;margin-top:10px"
              src="@/assets/zuo.png"> </i>
          <p class="batch_recharge"><span>订单管理</span><i>></i> 我的订单 </p>
          <div class="datechoice">
            <span style="color:#000;font-size:14px">查询条件</span>
            <select class="management-select" style="border: 1px solid #DCDFE6;">
              <option value="1">按支付号码</option>
              <option value="2">按激活码查询</option>
            </select>
            <el-input v-model="phone" placeholder="请输入" size="medium"></el-input>
            <el-button type="primary" style="float:right" size="medium"> <i
                style="font-style:normal;display:inline-block;width:58px;">&nbsp;查 &nbsp; &nbsp;询&nbsp;&nbsp;</i>
            </el-button>
          </div>
          <el-table :data="tableData" border size="medium" style="width: 100%">
            <el-table-column prop="order" label="流水单号" width="450">
              <template slot-scope="scope">
                <p v-if="scope.row.orderinput" class="content" @click="scope.row.orderinput = false">{{ scope.row.order }}</p>
                <p v-else class="content"><el-input v-model="scope.row.order" @change="scope.row.orderinput = true"
                    autofocus></el-input></p>
              </template>
            </el-table-column>
            <el-table-column prop="phone" label="发货号码" width="220">
              <template slot-scope="scope">
                <p v-if="scope.row.phoneinput" class="content" @click="scope.row.phoneinput = false">{{ scope.row.phone }}</p>
                <p v-else class="content"><el-input v-model="scope.row.phone" @change="scope.row.phoneinput = true"
                    autofocus></el-input></p>
              </template>
            </el-table-column>
            <el-table-column prop="time" width="320" label="交易时间">
              <template slot-scope="scope">
                <p v-if="scope.row.timeinput" class="content" @click="scope.row.timeinput = false">{{ scope.row.time }}</p>
                <p v-else class="content"><el-input v-model="scope.row.time" @change="scope.row.timeinput = true"
                    autofocus></el-input></p>
              </template>
            </el-table-column>
            <el-table-column prop="goodsname" width="360" label="交易商品">
              <template slot-scope="scope">
                <p v-if="scope.row.goodsnameinput" class="content" @click="scope.row.goodsnameinput = false">
                  {{ scope.row.goodsname }}</p>
                <p v-else class="content"><el-input v-model="scope.row.goodsname" @change="scope.row.goodsnameinput = true"
                    autofocus></el-input></p>
              </template>
            </el-table-column>
            <el-table-column width="150" label="活动号">
              <template>
                <span>无</span>
              </template>
            </el-table-column>
            <el-table-column label="交易状态">
              <template>
                <span>已到账</span>
              </template>
            </el-table-column>
          </el-table>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>

export default {
  name: "txorder",
  data() {
    return {
      total: 0,//默认数据总数
      pagesize: 9,//每页的数据条数
      currentPage: 1,//默认开始页面
      goodsname: "",
      //搜索的商品名称
      goodsid: "",
      //搜索商品id
      tableData: [{ order: '', phone: '', time: '', goodsname: '', orderinput: true, phoneinput: true, timeinput: true, goodsnameinput: true }],
      //供应商商品表格数据
      row_value: "",
      //点击行的值
      aside: true,
      phone: '',
    };
  },
  mounted() {

  },
  methods: {
    current_change: function (currentPage) {
      this.currentPage = currentPage;
    },
    //分页


  },
};
</script>
<style lang="less" scoped>
.bank_bind {
  width: 98%;
  min-height: 790px;
  margin: auto;
}

.title {
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  color: #000;
  padding-left: 20px;
  border-bottom: 1px solid #ccc;
}

.lab {
  font-size: 16px;
  margin: 10px 10px 10px 20px;
  display: inline-block
}

.fy {
  text-align: center;
  margin-top: 10px;
}

.set {
  color: #3743D6;
  cursor: pointer;
  width: 100px;
}

.active {
  background: #3743D6;
  color: #fff;
}

// .el-button--primary{color: #fff;background-color: #3743D6;border-color: #3743D6;}
// .el-button--primary:hover{color: #fff;background-color: #3a8ee6;border-color: #3a8ee6;}
.logo {
  display: inline-block;
  width: 275px;
  height: 70px;
  line-height: 70px;

  img> {
    width: 100%;
    height: 30px;
    margin-top: 25px;
    margin-left: 30px;
    cursor: pointer;
  }
}

.namediv {
  float: right;
  min-width: 120px;
  font-size: 15px;
  margin-top: 25px;
}

.management-select {
  min-width: 168px;
  color: #333;
  height: 36px;
  padding: 0;
  margin-left: 36px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(../../assets/sj.png) no-repeat scroll right 10px center transparent;
  padding-left: 11px;
  padding-right: 25px;
  cursor: pointer;
  border-radius: 2px;
  box-sizing: border-box;
}

.el-header {
  height: 74px !important;
}

.el-container {
  border-top: 1px solid #dadde0;
  height: 850px;
}

// .el-aside{background-color: #F2F5F7;height: 100%;border-right:1px solid #E2E2E2 ;position: relative;width: 200px !important;transition: all .3s;}
.aside1 {
  background-color: #F2F5F7;
  height: 100%;
  position: relative;
  width: 200px !important;
  transition: all .3s;
  z-index: 1;
}

.aside2 {
  background-color: #F2F5F7;
  height: 100%;
  position: relative;
  width: 45px !important;
  transition: all .3s;
  z-index: 1;
}

.icon-menu {
  display: inline-block;
  background: #f2f5f7;
  background-size: 10px 20px;
  width: 20px;
  height: 35px;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  border-left: 0;
  border-radius: 0 4px 4px 0;
  position: absolute;
  left: -1px;
  top: 17px;
  cursor: pointer;
  z-index: 10000;
  padding-left: 5px;
}

.el-main {
  position: relative;
  height: 100%;
  z-index: 100;
  padding-left: 0px;
  padding-right: 0;
  border-left: 1px solid #E2E2E2;
  overflow: initial
}

.datechoice {
  padding: 40px 48px 38px;
  width: 100%;
  display: table;
  box-sizing: border-box;
}

.batch_recharge {
  font-size: 21px;
  height: 29px;
  line-height: 29px;
  color: #000;
  font-family: Microsoft YaHei;
  margin-left: 45px;
  margin-bottom: 35px;

  >span {
    cursor: pointer;
    display: inline-block;
    width: auto;
    margin: 0 5px;
  }

  >i {
    font-style: normal;
  }
}

.el-input {
  width: 300px;
  margin-left: 20px;
}

.body /deep/ .el-table__body-wrapper tr td {
  font-weight: 400;
  color: #555;
  border-left: 0;
  border-right: 0;
}

.body /deep/ .has-gutter tr td {
  font-weight: normal;
  color: red;
}

.body /deep/ .el-table__header-wrapper tr th {
  font-weight: 400;
  color: #555;
  background-color: #fafafa;
}

.body /deep/ .el-table__header-wrapper tr th:first-child {
  font-weight: 400;
  color: #555;
  padding-left: 22px;
}

.body /deep/ .el-table__body-wrapper tr td:first-child {
  font-weight: 400;
  color: #555;
  padding-left: 22px;
}

.content {
  width: 98%;
  cursor: pointer;
  height: 50px;
  line-height: 50px;

  .el-input {
    width: 96%;
    margin-left: 0 !important;
  }
}

select {
  font-size: 14px;
}</style>